import React from 'react';
import { getStaticImageUrl } from 'client/utils/image-helpers';

export function FindVinByAddressNotFoundView() {
  return (
    <header className="mt-2 mb-2 pos-r text-gray-darker text-center">
      <img
        className="d-inline-block mx-auto mb-1"
        src={getStaticImageUrl('/img/eva/no-vin-found.svg')}
        alt="no vin found"
      />
      <h4 className="size-20">Unfortunately, we can&apos;t find your VIN at this time.</h4>
    </header>
  );
}
