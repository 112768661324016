import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { noop } from 'lodash';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import FormGroup from 'reactstrap/lib/FormGroup';

export function RadioListItem({
  listItem: { title, text, trackingId, trackingValue, id, customAttrs, hideValue },
  name,
  onChange,
  formGroupClassName,
  labelContainerClassName,
  checkmarkClassName,
  labelClassName,
  labelCheckedClassName,
  labelUncheckedClassName,
  textClassName,
  checked,
  useInlineTags,
  onClick,
  noAdRefresh,
}) {
  const Tag = useInlineTags ? 'span' : 'div';

  return (
    <FormGroup className={classNames(formGroupClassName)} check {...customAttrs}>
      <Label
        className={classNames(labelContainerClassName, {
          [labelCheckedClassName]: checked,
          [labelUncheckedClassName]: !checked,
        })}
        check
      >
        <Input
          className="radio-input"
          type="radio"
          value={title}
          name={name}
          checked={checked}
          onChange={onChange}
          onClick={onClick}
          data-tracking-id={trackingId}
          data-tracking-value={trackingValue}
          {...noAdRefresh && { 'data-no-refresh': true }}
        />
        <span className={classNames('checkmark', checkmarkClassName)} />
        {!hideValue && (
          <Tag id={id} className={classNames(labelClassName)}>
            {title}
          </Tag>
        )}
        {!!text && <Tag className={classNames(textClassName)}>{text}</Tag>}
      </Label>
    </FormGroup>
  );
}

RadioListItem.propTypes = {
  listItem: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    trackingId: PropTypes.string,
    trackingValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    customAttrs: PropTypes.shape({}),
    hideValue: PropTypes.bool,
  }).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  formGroupClassName: PropTypes.string,
  labelContainerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  labelCheckedClassName: PropTypes.string,
  labelUncheckedClassName: PropTypes.string,
  textClassName: PropTypes.string,
  checkmarkClassName: PropTypes.string,
  checked: PropTypes.bool,
  // div can't be a child of legend per html validation
  // useInlineTags is introduced for smooth transition from div to span
  // TODO: Remove this prop once all usages will be moved to spans
  useInlineTags: PropTypes.bool,
  onClick: PropTypes.func,
  noAdRefresh: PropTypes.bool,
};

RadioListItem.defaultProps = {
  formGroupClassName: null,
  labelContainerClassName: null,
  labelClassName: null,
  labelCheckedClassName: 'label-checked',
  labelUncheckedClassName: '',
  textClassName: null,
  checkmarkClassName: null,
  checked: false,
  useInlineTags: false,
  onClick: noop,
  noAdRefresh: false,
};
