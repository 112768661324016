import { TrackingConstant } from 'client/tracking/constant';
import { EventToolbox } from 'client/utils/event-toolbox';

export const fireSquishVinTracking = ({ isSquishVinValid, squishStyles }, vin, creativeId) => {
  const trackingData = {
    event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
    event_data: {
      action_name: TrackingConstant.ACTION_APPRAISE_VEHICLE,
      subaction_name: TrackingConstant.SUBACTION_SQUISHVIN_DECODE,
      action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
      action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
      creative_id: creativeId,
      value: TrackingConstant.MMY_NOT_FOUND,
    },
  };

  if (squishStyles.length) {
    EventToolbox.fireTrackAction({
      ...trackingData,
      event_data: {
        ...trackingData.event_data,
        value: squishStyles.length > 1 ? TrackingConstant.MMY_MANY_FOUND : TrackingConstant.MMY_ONE_FOUND,
      },
    });
    trackingData.event_data.value = isSquishVinValid ? TrackingConstant.MMY_MATCH : TrackingConstant.MMY_MISMATCH;
    trackingData.event_data.subaction_name = TrackingConstant.SUBACTION_SQUISHVIN_VALIDATION;
  }
  EventToolbox.fireTrackAction(trackingData);
  if ([TrackingConstant.MMY_NOT_FOUND, TrackingConstant.MMY_MISMATCH].includes(trackingData.event_data.value)) {
    EventToolbox.fireTrackAction({
      ...trackingData,
      event_data: {
        ...trackingData.event_data,
        subaction_name: TrackingConstant.SUBACTION_VIN_ERROR,
        value: `${vin}_${trackingData.event_data.subaction_name}_${trackingData.event_data.value}`,
      },
    });
  }
};
