import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Col, Form, FormGroup, Row } from 'reactstrap';
import { randomInt } from 'client/utils/seed-randomizers';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { StateEntities, StatesModel } from 'client/data/models/states';
import { FormField } from 'site-modules/shared/components/form-field/form-field';
import { FieldError } from 'site-modules/shared/components/field-error/field-error';
import { formValidation } from 'site-modules/shared/components/form-validation/form-validation';
import { ZipInput } from 'site-modules/shared/components/zip-input/zip-input';
import { validation } from 'site-modules/shared/components/form-validation/validation';
import { StyledSelect } from 'site-modules/shared/components/styled-select/styled-select';
import { Spinner } from 'site-modules/shared/components/spinner/spinner';
import { useAppraisalTabsContext } from 'site-modules/shared/components/appraisal/appraisal-tabs/appraisal-tabs-context';

export const VALIDATORS = {
  firstName: {
    test: validation.validateFirstName,
    errorText: 'Please correct your first name. First name cannot be empty.',
  },
  lastName: {
    test: validation.validateName,
    errorText: 'Please correct your last name. Last name cannot be empty.',
  },
  address: {
    test: validation.validateAddress,
    errorText: 'Please enter a valid street address. Street address cannot be empty.',
  },
  city: {
    test: validation.validateCity,
    errorText: 'Please enter a valid city. City cannot be empty.',
  },
  state: {
    test: validation.validateValue,
    errorText: 'Please select a state. State cannot be empty.',
  },
};

export function FindVinByAddressFormUI({
  isLandingPage,
  isMobile,
  validate,
  validationErrors,
  fields: { firstName, lastName, address, apartment, city },
  fieldRef,
  city: defaultCity,
  stateCode,
  stateCodes,
  ctaBtnColor,
  isSubmitting,
  onSubmit: handleSubmit,
  useUniqId,
  setStateCode,
  isAddressTab,
}) {
  const { addressInfo, setAddressInfo } = useAppraisalTabsContext();
  const {
    firstName: ctxFirstName,
    lastName: ctxLastName,
    address: ctxAddress,
    apartment: ctxApartment,
    city: ctxCity,
  } = addressInfo;
  const cityValue = ctxCity === undefined ? defaultCity || '' : ctxCity;

  const [isZipChangeInProgress, setIsZipChangeInProgress] = useState(false);
  const [stateCodeError, setStateCodeError] = useState('');
  const {
    firstName: firstNameError,
    lastName: lastNameError,
    address: addressError,
    apartment: apartmentError,
    city: cityError,
  } = validationErrors;

  const uniqIdRef = useRef(randomInt());
  const uniqId = useUniqId ? `-${uniqIdRef.current}` : '';

  useEffect(() => {
    if (stateCode) {
      setStateCodeError('');
    }
  }, [stateCode]);

  const getRef = useCallback(
    field => {
      if (!field) {
        return undefined;
      }

      return fieldRef(field.props.name, field);
    },
    [fieldRef]
  );

  const handleZipCodeChangeProgress = useCallback(state => setIsZipChangeInProgress(state.isZipChangeInProgress), []);

  const onChange = useCallback(
    ({ target }) => {
      const { value, name } = target;
      setAddressInfo({
        ...addressInfo,
        [name]: value,
      });
      validate(value);
    },
    [addressInfo, setAddressInfo, validate]
  );

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();

      let isValid = validate();

      if (!VALIDATORS.state.test(stateCode)) {
        setStateCodeError(VALIDATORS.state.errorText);
        isValid = false;
      }

      handleSubmit(isValid, {
        firstName: firstName.value(),
        surname: lastName.value(),
        address: address.value(),
        apartment: apartment.value(),
        city: city.value(),
      });
    },
    [address, apartment, city, firstName, handleSubmit, lastName, stateCode, validate]
  );

  const firstNameErrorId = `first-name-error${uniqId}`;
  const lastNameErrorId = `last-name-error${uniqId}`;
  const addressErrorId = `address-error${uniqId}`;
  const cityErrorId = `city-error${uniqId}`;
  const stateCodeErrorId = `state-code-error${uniqId}`;

  return (
    <Form noValidate onSubmit={onSubmit}>
      <Row>
        <Col xs={12} lg={!isLandingPage ? 12 : 6}>
          <div>
            <FormField
              id={`first-name${uniqId}`}
              maxLength={255}
              name="firstName"
              type="text"
              label="First Name"
              labelClassName={classNames('small mb-0_5', {
                'bg-white px-0_25 top-styled-label': !isLandingPage,
              })}
              inputClassName={classNames({
                'size-16': isMobile,
              })}
              value={ctxFirstName}
              ref={getRef}
              isValid={!firstNameError}
              required
              onBlur={validate}
              onChange={onChange}
              className={classNames('pos-r', {
                'mb-1_5': !isLandingPage && !firstNameError,
                'mb-1': !isLandingPage && firstNameError,
              })}
              ariaInvalid={!!firstNameError}
              ariaDescribedBy={firstNameErrorId}
            />
            <FieldError id={firstNameErrorId} error={firstNameError} classes="mb-1" />
          </div>
        </Col>
        <Col xs={12} lg={!isLandingPage ? 12 : 6}>
          <div>
            <FormField
              id={`last-name${uniqId}`}
              maxLength={255}
              name="lastName"
              type="text"
              label="Last Name"
              labelClassName={classNames('small mb-0_5', {
                'bg-white px-0_25 top-styled-label': !isLandingPage,
              })}
              inputClassName={classNames({
                'size-16': isMobile,
              })}
              value={ctxLastName}
              ref={getRef}
              isValid={!lastNameError}
              required
              onBlur={validate}
              onChange={onChange}
              className={classNames('pos-r', {
                'mb-1_5': !isLandingPage && !lastNameError,
                'mb-1': !isLandingPage && lastNameError,
              })}
              ariaInvalid={!!lastNameError}
              ariaDescribedBy={lastNameErrorId}
            />
            <FieldError id={lastNameErrorId} error={lastNameError} classes="mb-1" />
          </div>
        </Col>
      </Row>
      <div>
        <FormField
          id={`address${uniqId}`}
          maxLength={255}
          name="address"
          type="text"
          label="Street address"
          labelClassName={classNames('small mb-0_5', {
            'bg-white px-0_25 top-styled-label': !isLandingPage,
          })}
          inputClassName={classNames({
            'size-16': isMobile,
          })}
          value={ctxAddress}
          ref={getRef}
          isValid={!addressError}
          placeholder={!isLandingPage ? '' : 'Enter street address'}
          required
          onBlur={validate}
          onChange={onChange}
          className={classNames('pos-r', {
            'mb-1_5': !isLandingPage && !addressError,
            'mb-1': !isLandingPage && addressError,
          })}
          ariaInvalid={!!addressError}
          ariaDescribedBy={addressErrorId}
        />
        <FieldError id={addressErrorId} error={addressError} classes="mb-1" />
      </div>
      <FormField
        id={`apartment${uniqId}`}
        maxLength={255}
        name="apartment"
        type="text"
        label="Apartment, suite, etc."
        labelClassName={classNames('small mb-0_5', {
          'bg-white px-0_25 top-styled-label': !isLandingPage,
        })}
        inputClassName={classNames({
          'size-16': isMobile,
        })}
        value={ctxApartment}
        ref={getRef}
        isValid={!apartmentError}
        onChange={onChange}
        placeholder={!isLandingPage ? '' : 'Enter apartment, suite, etc.'}
        isOptional
        className={classNames({ 'pos-r mb-1_5': !isLandingPage })}
      />
      <div>
        <div>
          <FormField
            id={`city${uniqId}`}
            maxLength={255}
            name="city"
            type="text"
            label="City"
            labelClassName={classNames('small mb-0_5', {
              'bg-white px-0_25 top-styled-label': !isLandingPage,
            })}
            inputClassName={classNames({
              'size-16': isMobile,
            })}
            value={cityValue}
            ref={getRef}
            isValid={!cityError}
            required
            onBlur={validate}
            onChange={onChange}
            className={classNames('pos-r', {
              'mb-1_5': !isLandingPage && !cityError,
              'mb-1': !isLandingPage && cityError,
            })}
            ariaInvalid={!!cityError}
            ariaDescribedBy={cityErrorId}
          />
          <FieldError id={cityErrorId} error={cityError} classes="mb-1" />
        </div>
        <Row>
          <Col xs={6}>
            <FormGroup className={classNames({ 'has-danger': stateCodeError })}>
              <StyledSelect
                name="state"
                labelText="State"
                labelClassName={classNames('small mb-0_5', {
                  'bg-white px-0_25 top-styled-label': !isLandingPage,
                })}
                toggle="Select State"
                labelKey="stateCode"
                valueKey="stateCode"
                options={stateCodes}
                value={stateCode}
                className={isMobile ? 'select-state-mobile' : undefined}
                onChange={setStateCode}
                ariaInvalid={!!stateCodeError}
                ariaDescribedBy={stateCodeErrorId}
              />
              <FieldError id={stateCodeErrorId} error={stateCodeError} classes="mt-0_5" />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <ZipInput
              label="Zip"
              onValidate={validation.validateZip}
              labelClasses={classNames('small mb-0_5', {
                'bg-white px-0_25 top-styled-label zip-label': !isLandingPage,
              })}
              inputClasses={classNames({
                'size-16': isMobile,
              })}
              inputSize="md"
              isUpdateZipOnChange
              isLocationAddressZip
              onZipChangeInProgress={handleZipCodeChangeProgress}
              wrapperClasses="form-group"
            />
          </Col>
        </Row>
      </div>
      <Row className="mt-0_5 align-items-center">
        <Col xs={12} {...(!isLandingPage ? { md: 7, lg: 6, xl: 7 } : {})}>
          <Button
            type="submit"
            color={ctaBtnColor}
            className={classNames('text-white text-transform-none size-16', {
              'w-100 py-0_5': !isAddressTab,
              'font-weight-medium': isAddressTab,
              'w-100': isAddressTab && isMobile,
            })}
            disabled={isZipChangeInProgress || isSubmitting}
            size={isAddressTab ? 'lg' : undefined}
          >
            {isSubmitting && (
              <span className="pr-0_5">
                <Spinner size={14} thickness={1} color="white" />
              </span>
            )}
            <span className="size-16">Look up my VIN</span>
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

FindVinByAddressFormUI.propTypes = {
  isLandingPage: PropTypes.bool,
  isMobile: PropTypes.bool,
  validate: PropTypes.func.isRequired,
  validationErrors: PropTypes.objectOf(PropTypes.string),
  fields: PropTypes.shape({
    firstName: PropTypes.shape({
      value: PropTypes.func,
    }),
    lastName: PropTypes.shape({
      value: PropTypes.func,
    }),
    address: PropTypes.shape({
      value: PropTypes.func,
    }),
    apartment: PropTypes.shape({
      value: PropTypes.func,
    }),
    city: PropTypes.shape({
      value: PropTypes.func,
    }),
  }),
  fieldRef: PropTypes.func.isRequired,
  city: PropTypes.string,
  stateCode: PropTypes.string,
  stateCodes: StateEntities.States,
  ctaBtnColor: PropTypes.string,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  useUniqId: PropTypes.bool,
  setStateCode: PropTypes.func.isRequired,
  isAddressTab: PropTypes.bool,
};

FindVinByAddressFormUI.defaultProps = {
  isLandingPage: false,
  city: '',
  stateCode: '',
  ctaBtnColor: '',
  isSubmitting: false,
  isMobile: false,
  stateCodes: [],
  validationErrors: {},
  fields: {},
  useUniqId: false,
  isAddressTab: false,
};

export const stateToPropsConfig = {
  stateCodes: bindToPath('allStateCodes', StatesModel),
};

export const FindVinByAddressForm = connectToModel(
  formValidation(FindVinByAddressFormUI, VALIDATORS),
  stateToPropsConfig
);
